import * as React from 'react'

import { Column } from '@wicadu/arepa/ui'
import styled from '@emotion/styled'

import useTemplate from '@hooks/useTemplate'
import { LegalHomeScreen } from '@ts-types/Pages/Legal/Home'
import TopicsLinkList from '@organisms/TopicsLinkList'
import Layout from '@HOCs/Layout/Layout'
import Consts from '@utils/constants'
import FaqSection from '@organisms/FaqSection'

function LegalHomeTemplate() {
  const { translate, lang, topics, faqs } = useTemplate<LegalHomeScreen>()

  return (
    <Layout>
      <Layout.PageGrid
        withBackArrow
        title={translate[lang].TITLE as string}
        description={translate[lang].DESCRIPTION as string}
        breadcrumbs={[
          {
            name: translate[lang].BREADCRUMBS['HOME'],
            path: `/${lang}/`,
          },
        ]}
        returnUrl={`/${lang}/`}
      >
        <Column gap={25}>
          <TopicsContainer gap={15}>
            {topics.map(({ items, title }: any, index: number) => (
              <TopicsLinkList key={index} title={title} items={items} />
            ))}
          </TopicsContainer>
        </Column>

        <FaqSection faqs={faqs} />
      </Layout.PageGrid>
    </Layout>
  )
}

const { SMALL_DEVICE } = Consts.breakpoints

const TopicsContainer = styled(Column)`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;

  @media screen and (min-width: ${SMALL_DEVICE}px) {
    grid-template-columns: 1fr 1fr;
  }
`

export default LegalHomeTemplate
