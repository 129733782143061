import React, { useMemo } from 'react'

import { Column, Dropdown, useTheme } from '@wicadu/arepa/ui'
import styled from '@emotion/styled'

import Layout from '@HOCs/Layout/Layout'
import Consts from '@utils/constants'
import { usePageContext } from '@HOCs/Providers/PageContext'
import { type Faq } from '@ts-types/Faq'

const { COMMON_TRANSLATIONS } = Consts.translates

interface Props {
  faqs: Faq[]
}

const defaultProps: Partial<Props> = {
  faqs: [],
}

function FaqSection(props: Props) {
  const { faqs } = {
    ...defaultProps,
    ...props,
  }

  const { lang } = usePageContext()
  const { colors } = useTheme()

  const placeholderProps = useMemo(
    () => ({
      type: 'title-3',
      color: colors.FONT.TITLE,
    }),
    [colors]
  )

  return (
    <Layout.Section
      title={COMMON_TRANSLATIONS[lang].FAQ_TITLE}
      titleProps={{
        type: 'title-3',
      }}
    >
      <Column gap={0}>
        {faqs.map(({ question, anwser }: Faq) => (
          <StyledDropdown
            placeholderProps={placeholderProps}
            placeholder={question}
            options={[{ _html: anwser }]}
            wrapperTag="div"
            itemTag="span"
          />
        ))}
      </Column>
    </Layout.Section>
  )
}

const StyledDropdown = styled(Dropdown)`
  [type='dropdown-content-wrapper'] {
    * {
      color: ${({ theme }) => theme.colors.FONT.TITLE};
      font-size: 15px;
      line-height: 24px;
    }

    ul {
      padding-left: 25px;
    }

    a {
      color: ${({ theme }) => theme.colors.MAIN.INFO};
      font-weight: 700;
      text-decoration: none;
    }

    a:hover {
      text-decoration: underline;
    }
  }
`

export default FaqSection
