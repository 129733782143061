import React from 'react'

import type { PageProps } from 'gatsby'

import { TemplateProvider } from '@hooks/useTemplate'
import HeadSEO from '@atoms/HeadSEO'
import { usePageContext } from '@HOCs/Providers/PageContext'
import LegalHomeTemplate from '@templates/Legal/Home'
import Consts from '@utils/constants'
import { IPageProps } from '@ts-types/Page'
import { LanguageTranslations, Translations } from '@ts-types/Translations'
import { getUrlByPageId } from '@utils/getUrlByPageId'
import { type Faq } from '@ts-types/Faq'
import { PAGES_ID } from '@config/pages/const'
import { resolvePagePath } from '@utils/resolvePagePath'

interface Props extends IPageProps {}

const {
  LEGAL_HOME_TRANSLATION,
  LEGAL_PRIVACY_POLICY_TRANSLATION,
  LEGAL_TERMS_OF_USE_TRANSLATION,
} = Consts.translates

const _LEGAL_INFO_LIST = [
  LEGAL_PRIVACY_POLICY_TRANSLATION,
  LEGAL_TERMS_OF_USE_TRANSLATION,
]

const LegalHomeContainer: React.FC<PageProps> = () => {
  const { lang } = usePageContext()

  const topics = [
    {
      title: LEGAL_HOME_TRANSLATION[lang].LEGAL_INFORMATION,
      items: _LEGAL_INFO_LIST.map((t: Translations) => ({
        label: t[lang].ARTICLE['TITLE'],
        url: resolvePagePath(t[lang].PAGE_ID, lang)?.pathname || '',
      })),
    },
  ]

  const faqs: Faq[] = (
    LEGAL_HOME_TRANSLATION[lang].FAQ as LanguageTranslations[]
  ).map(({ QUESTION, ANSWER }: LanguageTranslations) => ({
    question: QUESTION as string,
    anwser: ANSWER as string,
  }))

  return (
    <TemplateProvider
      value={{ translate: LEGAL_HOME_TRANSLATION, lang, topics, faqs }}
    >
      <LegalHomeTemplate />
    </TemplateProvider>
  )
}

export function Head({ pageContext }: Props) {
  const { lang } = pageContext || {}

  const { organization, logo } = Consts.schemeOrg

  const faqList = (
    LEGAL_HOME_TRANSLATION[lang].FAQ as LanguageTranslations[]
  ).map(({ QUESTION, ANSWER }: LanguageTranslations) => ({
    '@type': 'Question',
    name: QUESTION,
    headline: QUESTION,
    answerCount: 1,
    datePublished: '2025-03-23T00:00:00Z',
    dateModified: '2025-03-23T00:00:00Z',
    author: organization.organizationField,
    acceptedAnswer: {
      '@type': 'Answer',
      text: ANSWER,
    },
  }))

  const legalInfoList = _LEGAL_INFO_LIST.map(
    (t: Translations, index: number) => ({
      '@type': 'Article',
      position: index + 1,
      image: logo.logoField,
      author: organization.organizationField,
      headline: t[lang].SEO_TITLE,
      name: t[lang].SEO_TITLE,
      url: getUrlByPageId(t[lang].PAGE_ID, lang),
      description: t[lang].SEO_DESCRIPTION,
      keywords: t[lang].SEO_KEYWORDS,
    })
  )

  return (
    <>
      <HeadSEO
        title={LEGAL_HOME_TRANSLATION[lang].TITLE as string}
        description={LEGAL_HOME_TRANSLATION[lang].DESCRIPTION as string}
        keywords={LEGAL_HOME_TRANSLATION[lang].SEO_KEYWORDS as string}
        lang={lang}
        pageId={PAGES_ID.LEGAL_HOME}
        jsonLd={[
          {
            '@type': 'WebPage',
            '@id': getUrlByPageId(PAGES_ID.LEGAL_HOME, lang),
            name: LEGAL_HOME_TRANSLATION[lang].TITLE,
            description: LEGAL_HOME_TRANSLATION[lang].DESCRIPTION,
            url: getUrlByPageId(PAGES_ID.LEGAL_HOME, lang),
            breadcrumb: {
              '@type': 'BreadcrumbList',
              itemListElement: [
                {
                  '@type': 'ListItem',
                  position: 1,
                  name: LEGAL_HOME_TRANSLATION[lang].BREADCRUMBS['HOME'],
                  item: getUrlByPageId(PAGES_ID.HOME, lang),
                },
                {
                  '@type': 'ListItem',
                  position: 2,
                  name: LEGAL_HOME_TRANSLATION[lang].BREADCRUMBS['LEGAL_HOME'],
                  item: getUrlByPageId(PAGES_ID.LEGAL_HOME, lang),
                },
              ],
            },
            mainEntity: {
              '@type': 'FAQPage',
              mainEntity: faqList,
              headline: LEGAL_HOME_TRANSLATION[lang].TITLE,
              reviewedBy: organization.organizationField,
              author: organization.organizationField,
              publisher: organization.organizationField,
              datePublished: '2025-03-23T00:00:00Z',
              dateModified: '2025-03-23T00:00:00Z',
              keywords: LEGAL_HOME_TRANSLATION[lang].SEO_KEYWORDS as string,
            },
          },
          {
            '@type': 'ItemList',
            itemListOrder: 'https://schema.org/ItemListOrderUnordered',
            name: LEGAL_HOME_TRANSLATION[lang].LEGAL_INFORMATION,
            itemListElement: legalInfoList,
          },
        ]}
      />
    </>
  )
}

export default LegalHomeContainer
